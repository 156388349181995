<template>
  <div>
    <div v-if="isDescription">
      <!-- Description with quill editor -->
      <div v-if="isRich">
        <b-form-select
          :id="id"
          v-model="selected"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          class="mb-1"
          style="width: 200px"
          @keydown.enter.prevent="onEnter"
        />
        <quill-editor
          :value="value[selected]"
          :options="options"
          style="border-radius: 0.357rem;"
          @input="handleInput(selected, $event)"
          @keydown.enter.prevent="onEnter"
        />
      </div>
      <!-- Description with textarea -->
      <div v-else>
        <b-form-select
          :id="id"
          v-model="selected"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          class="mb-1"
          @keydown.enter.prevent="onEnter"
        />
        <b-form-textarea
          :value="value[selected]"
          type="text"
          :rows="rows"
          :placeholder="$t('form.type-placeholder')"
          @input="handleInput(selected, $event)"
          @keydown.enter.prevent="onEnter"
        />
      </div>
    </div>
    <!-- Input -->
    <b-input-group v-else>
      <b-input-group-prepend>
        <b-form-select
          :id="id"
          v-model="selected"
          :options="locales"
          :placeholder="$t('form.type-placeholder')"
          label-type="text"
          @keydown.enter.prevent="onEnter"
        />
      </b-input-group-prepend>
      <b-form-input
        :value="value[selected]"
        type="text"
        class="form-control"
        :placeholder="$t('form.type-placeholder')"
        @input="handleChanger(selected, $event)"
        @keydown.enter.prevent="onEnter"
      />
    </b-input-group>
  </div>
</template>

<script>
import {
  BFormInput, BFormTextarea, BFormSelect, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'FormTranslationTable',
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    quillEditor,
  },
  props: {
    id: {
      type: String,
      default: `${Math.random()}`,
    },
    value: {
      type: [Object, String], // TranslationTable,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: null,
    },
    valuePlaceholder: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    isDescription: {
      type: Boolean,
      default: false,
    },
    isRich: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: '',
      timer: false,
    };
  },
  computed: {
    locales() {
      const locales = this.$store.getters.locales;
      const communityLanguages = [];
      for(const language of locales){
        if(this.availableLanguages.find(item=>item === language.value)){
          communityLanguages.push(language)
        }
      }
      return communityLanguages;
    },
    availableLanguages() {
      return this.$store.getters.currentCollective?.availableLanguages;
    },
    currentLocale() {
      const currentLocale = this.$store.state.locale.currentLocale;
      return this.availableLanguages?.find(item=>item === currentLocale)? currentLocale : this.collective.languageCode
    },
  },
  created() {
    this.getLocaleInputs();
  },
  methods: {
    handleChanger(locale, localeValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.handleInput(locale, localeValue);
      }, 800);
    },
    async handleInput(locale, localeValue) {
      this.value[locale] = localeValue;
      this.$emit('input', {
        ...this.value,
        [locale]: localeValue,
      });
    },
    getLocaleInputs() {
      this.selected = this.currentLocale;
    },
    onEnter() {
      this.$emit('input', [...this.info]);
    },
  },
};
</script>
