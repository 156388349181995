<template>
  <div>
    <!-- REMOVE Comment MODAL -->
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="blog"
      @remove-item="deleteItem(item)"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <unshare-item-modal
      v-model="isUnshareCommentModalVisible"
      model-type="comment"
      @remove-item="deleteComment(commentKey)"
      @modal-invisible="isUnshareCommentModalVisible = false"
    />
    <share-modal
      v-if="item && item.title"
      v-model="isShareModalVisible"
      :can-share="canShare"
      :item-name="translateTranslationTable(currentLocale, item.title)"
      model-type="blog"
      type="blogs"
      :model-key="item.key"
    />
    <b-dropdown
      v-if="canEdit||canDelete||canShare "
      id="dropdown-dropleft"
      variant="link"
      right
      class="d-block d-lg-none position-absolute"
      no-caret
      style="top:102px; right:-5px"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="20"
          class="text-primary mt-25 mt-lg-0 mr-n50 mr-md-50 pr-lg-0 d-block"
        />
      </template>
      <b-dropdown-text class="bg-light">
        <p class="user-name font-weight-bolder mb-0">
          {{ $t("settings.details.title") }}
        </p>
      </b-dropdown-text>
      <b-dropdown-item v-if="canEdit" @click="editing = true">
        {{ $t("settings.details.edit") }}
      </b-dropdown-item>
      <b-dropdown-item v-if="canDelete" @click="isUnshareModalVisible = true">
        {{ $t("settings.details.delete") }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canShare"
        class="d-block"
        @click="isShareModalVisible = true"
      >
        {{ $t("settings.details.share") }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="float-right mr-2 d-none d-lg-block" style="width: 200px">
      <div class="mt-n3 position-absolute" style="width: 200px">
        <feather-icon
          v-if="canEdit && !editing"
          v-b-tooltip.hover.bottom
          icon="Edit2Icon"
          :title="$t('tooltips.crud.edit')"
          size="20"
          class="text-primary edit-icon float-right ml-1"
          role="button"
          @click="editing = !editing"
        />
        <feather-icon
          v-if="canShare && !editing"
          v-b-tooltip.hover.bottom
          icon="Share2Icon"
          :title="$t('tooltips.crud.share')"
          size="20"
          class="text-primary edit-icon float-right ml-1"
          @click="isShareModalVisible = true"
        />
        <feather-icon
          v-if="canDelete && !editing"
          v-b-tooltip.hover.bottom
          icon="Trash2Icon"
          size="20"
          :title="$t('tooltips.crud.delete')"
          class="text-primary edit-icon float-right ml-1"
          role="button"
          @click="isUnshareModalVisible = true"
        />
        <feather-icon
          v-if="editing"
          icon="XCircleIcon"
          size="20"
          class="text-primary edit-icon float-right ml-1"
          role="button"
          @click="editing = !editing"
        />
        <b-button
          v-if="editing"
          class="float-right mt-n50"
          variant="primary"
          :disabled="editSlug == ''"
          @click="handleEditItem"
        >
          {{ $t("articles.save-changes") }}
        </b-button>
      </div>
    </div>
    <div class="blog-container" :class="isMobile?'my-2':'px-4 my-3'">
      <b-spinner
        v-if="isLoading"
        class="center-x"
        small
        variant="primary"
        type="grow"
      />
      <content-with-sidebar
        v-if="item && isLoading === false"
        class="cws-container cws-sidebar-right blog-wrapper"
      >
        <!-- content -->
        <div class="blog-detail-wrapper">
          <b-row class="justify-content-md-center">
            <!-- blogs -->
            <b-col cols="12">
              <b-card
                no-body
                style="overflow: hidden"
                img-alt="Blog Detail Pic"
              >
                <div class="mb-2 w-100">
                  <img
                    class="w-100 imagen"
                    :src="item.bannerURL || item.imageURL || placeholder"
                  >
                  <input
                    ref="file"
                    type="file"
                    style="display: none"
                    @change="onFileChange"
                  >
                  <div v-if="canEdit && editing" v-b-modal.modal-edit-project class="card-imgage-edit">
                    <small class="text-white">{{ $t('blogs.edit-image') }}</small>
                    <feather-icon
                      icon="Edit2Icon"
                      size="20"
                      class="text-white edit-icon float-right ml-50"
                      role="button"
                    />
                  </div>
                </div>
                <b-modal
                  id="modal-edit-project"
                  :title="$t('blogs.edit-image')"
                  cancel-variant="outline-secondary"
                  :ok-title="$t('form.actions.save')"
                  :cancel-title="$t('form-create-item.cancel')"
                  centered
                  size="lg"
                >
                  <b-row>
                    <b-col color="12" md="6">
                      <b-form-group :label="$t('backoffice.onboarding-form.form.image')" label-for="image" class="d-inline-block">
                        <file-preview
                          v-if="editForm.imageURL"
                          :src="getImageResource(editForm.imageURL)"
                          mime-type="image"
                          cancelable

                          @cancel="editForm.imageURL = null"
                        />
                        <file-upload
                          v-else
                          v-model="imageURL"
                          type="image"
                          cancelable
                          :placeholder="$t('form-create-item.image-button')"
                          :drop-placeholder="$t('form-create-item.drop-placeholder')"
                          @cancel="imageURL = null"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col color="12" md="6">
                      <b-form-group label="Banner" label-for="banner" class="d-inline-block">
                        <file-preview
                          v-if="editForm.bannerURL"
                          :src="getImageResource(editForm.bannerURL)"
                          mime-type="image"
                          cancelable

                          @cancel="editForm.bannerURL = null"
                        />
                        <file-upload
                          v-else
                          v-model="bannerURL"
                          type="image"
                          cancelable
                          :placeholder="$t('form-create-item.image-button')"
                          :drop-placeholder="$t('form-create-item.drop-placeholder')"
                          @cancel="bannerURL = null"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-modal>
                <b-input-group v-if="editing" :class="isMobile?'px-2':'px-3'" class="mb-1">
                  <template #prepend>
                    <b-input-group-text class="bg-light">
                      {{ `${currentUrl}/` }}
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="editSlug"
                    :state="editSlug != ''"
                    :placeholder="$t('form-create-item.slug')"
                  />
                </b-input-group>
                <div :class="isMobile?'px-2':'px-3'">
                  <h1 v-if="!editing">
                    {{
                      translateTranslationTable(currentLocale, item.title)
                    }}
                  </h1>
                  <b-form-input
                    v-else
                    v-model="editForm.title"
                    class="edit-title"
                    :placeholder="
                      translateTranslationTable(currentLocale, item.title)
                    "
                  />
                  <h4 v-if="item.headline && !editing" class="html-text-ellipsis-5 mb-1">
                    {{ item.headline[currentLocale] || Object.values(item.headline)[0] }}
                  </h4>
                  <b-form-input
                    v-if="editing"
                    v-model="editForm.headline"
                    :placeholder="$t('form-create-item.headline-placeholder')"
                    class="mt-1"
                  />
                  <b-media no-body>
                    <!-- Avatar -->

                    <!-- Owner and date-->
                    <b-media-body v-if="item.poweredBy" :class="isMobile?'mb-2':'mb-3'">
                      <small class="text-muted mr-50">{{ $t("blogs.by") }}</small>
                      <small>
                        <b-link class="text-body">
                          {{ translate(item.poweredBy.name) }}
                        </b-link>
                      </small>
                      <span class="text-muted ml-75 mr-50">|</span>
                      <small v-if="!editing" class="text-muted">{{
                        timestampToTime(item.date)
                      }}</small>
                      <VueCtkDateTimePicker
                        v-else
                        id="date"
                        v-model="date"
                        :locale="$store.state.locale.currentLocale"
                        format="YYYY-MM-DD"
                        formatted="ll"
                        :label="$t('backoffice.availability.date-no-slots')"
                        color="var(--primary)"
                        no-button-now
                        only-date
                        button-color="var(--primary)"
                      />
                    </b-media-body>
                  </b-media>
                </div>

                <!-- Description -->

                <div
                  v-if="item.content && !editing"
                  class="blog-content content-container"
                  :class="isMobile?'mx-2 mb-1':'mx-3 mb-3'"
                  v-html="
                    item.content[currentLocale] ||
                      Object.values(item.content)[0]
                  "
                />
                <quill-editor
                  v-if="editing"
                  v-model="editForm.description"
                  class="float-left"
                  :class="isMobile?'mx-2 mb-1':'mx-3 mb-3'"
                />

                <!-- user creator info -->
                <b-media
                  v-if="item.poweredBy"
                  class="d-flex align-items-center"
                  :class="isMobile?'ml-2 mr-2':'ml-3 mr-3'"
                  no-body
                >
                  <b-media-aside>
                    <b-avatar size="24" :src="item.poweredBy.logoURL" />
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="font-weight-bolder mb-0">
                      {{ translate(item.poweredBy.name) }}
                    </h6>
                    <b-card-text>
                      <p
                        v-if="item.poweredBy.headline"

                        class="html-text-ellipsis-2 mb-0"
                        v-html="translate(item.poweredBy.headline)"
                      />
                      <p
                        v-if="!item.poweredBy.headline && item.poweredBy.description"
                        class="html-text-ellipsis-2 mb-0"
                        v-html="
                          translate(item.poweredBy.description)
                        "
                      />
                    </b-card-text>
                  </b-media-body>
                </b-media>

                <hr class="my-2">

                <!-- Icons -->
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                  :class="isMobile?'':'mx-2'"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center ml-2">
                      <span class="mr-1">
                        <feather-icon
                          role="button"
                          size="20"
                          :class="
                            item.likedByMember
                              ? 'profile-likes'
                              : 'profile-icon'
                          "
                          icon="HeartIcon"
                          class="mr-25"
                          @click="toggleLike"
                        />
                        {{ item.totalLikes || 0 }}
                      </span>
                      <span>
                        <feather-icon
                          role="button"
                          size="20"
                          icon="MessageSquareIcon"
                          class="mr-25"
                          @click="scrollBottom"
                        />
                        {{ item.commentCount || 0 }}
                      </span>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!--/ blogs -->

            <!-- blog comments -->
            <b-col
              v-if="comments.length > 0"
              id="blogComment"
              cols="12"
              class="mt-2"
            >
              <h6 class="section-label">
                {{ $t("blogs.comments") }}
              </h6>
              <!-- Card Comment -->
              <b-card
                v-for="comment in comments"
                :key="comment.key"
                class="mb-1"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      :src="getImageSrc(comment.createdBy.avatarURL)"
                      size="38"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="float-left w-100">
                      <h6 class="float-left font-weight-bolder mb-2 pt-75">
                        {{ comment.createdBy.name }}
                        {{ comment.createdBy.surname }}
                      </h6>
                      <span
                        v-if="comment.createdAt"
                        class="float-right text-muted"
                      >
                        {{ timestampToNow(comment.createdAt) }}
                      </span>
                    </div>
                    <quill-editor
                      v-if="editingComment && commentKey === comment.key"
                      v-model="editCommentForm.description"
                      class="mx-3 float-left content-container"
                      :class="isMobile?'mb-2':'mb-3'"
                    />
                    <b-card-text v-else class="mt-1 content-container">
                      <p v-html="getText(comment.content)" />
                    </b-card-text>
                  </b-media-body>
                  <div class="float-right mr-2 mt-3" style="width: 200px">
                    <div class="mt-n3 position-absolute" style="width: 200px">
                      <feather-icon
                        v-if="canEditComment(comment) && !editingComment"
                        icon="Edit2Icon"
                        size="20"
                        class="text-primary edit-icon float-right ml-1"
                        role="button"
                        @click="selectComment(comment)"
                      />
                      <feather-icon
                        v-if="canDeleteComment(comment) && !editingComment"
                        icon="Trash2Icon"
                        size="20"
                        class="text-primary edit-icon float-right ml-1"
                        role="button"
                        @click="
                          (commentKey = comment.key),
                          (isUnshareCommentModalVisible = true)
                        "
                      />
                      <feather-icon
                        v-if="editingComment && commentKey === comment.key"
                        icon="XCircleIcon"
                        size="20"
                        class="text-primary edit-icon float-right ml-1"
                        role="button"
                        @click="editingComment = !editingComment"
                      />
                      <b-button
                        v-if="editingComment && commentKey === comment.key"
                        class="float-right mt-n50"
                        variant="primary"
                        @click="handleEditComment"
                      >
                        {{ $t("articles.save-changes") }}
                      </b-button>
                    </div>
                  </div>
                </b-media>
              </b-card>
            </b-col>

            <b-col cols="12">
              <h6 class="section-label ml-1">
                {{ $t("blogs.new-comment") }}
              </h6>
              <b-card id="commentForm">
                <b-form>
                  <b-row>
                    <b-col cols="12">
                      <quill-editor
                        v-model="newComment"
                        class="mb-2 w-100 float-left"
                      />
                    </b-col>

                    <b-col cols="12">
                      <b-button variant="primary" @click="postComment">
                        {{ $t("blogs.post-comment") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <!-- Sidebar, relate blogs -->
        <div
          v-if="!isMobile || recentPosts.length>0"
          slot="sidebar"
          class="blog-sidebar py-lg-0"
        >
          <div class="blog-recent-posts">
            <h6 class="section-label mb-1">
              {{ $t("blogs.recent-posts") }}
            </h6>
            <b-media
              v-for="(recentpost, index) in recentPosts"
              v-show="recentPosts.length>0"
              :key="recentpost.img"
              no-body
              :class="index ? 'mt-2' : ''"
            >
              <b-media-aside class="mr-2">
                <b-link :to="{ name: 'blog', params: { id: recentpost.key } }">
                  <b-img
                    :src="recentpost.bannerURL ||recentpost.imageURL || placeholder"
                    rounded
                    style="width: 100px; height: 70px; object-fit: contain;"
                  />
                </b-link>
              </b-media-aside>
              <b-media-body>
                <h6 class="blog-recent-post-title mb-0">
                  <b-link
                    class="text-body-heading html-text-ellipsis-2"
                    :to="{ name: 'blog', params: { id: recentpost.key } }"
                  >
                    {{
                      recentpost.title[currentLocale] ||
                        Object.values(recentpost.title)[0]
                    }}
                    <small v-if="recentpost.headline"><p class="mb-25">{{
                      recentpost.headline[currentLocale] ||
                        Object.values(recentpost.headline)[0]
                    }}</p>
                    </small>
                  </b-link>
                </h6>
                <span class="text-muted mb-0">
                  {{ timestampToNow(recentpost.date) }}
                </span>
              </b-media-body>
            </b-media>
            <b-row v-if="recentPosts.length===0" class="horizontal-placeholder">
              <b-col cols="12">
                <img :src="blogPlaceholder">
              </b-col>
              <b-col cols="12">
                <p class="text-primary">
                  {{ $t("blogs.recent-posts-placeholder") }}
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </content-with-sidebar>
    </div>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue';
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import { quillEditor } from 'vue-quill-editor';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import vSelect from 'vue-select';
import { checkPermissions } from '@/@core/utils/roles-utils';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import BlogPlaceholder from '@/assets/images/placeholders/light/blog.svg';
import Vue from 'vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';

export default {
  name: 'BlogDetails',
  components: {
    ContentWithSidebar,
    quillEditor,
    FormTranslationTable,
    vSelect,
    UnshareItemModal,
    ShareModal,
    FileUpload,
    FilePreview,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      imageSrc: null,
      imageURL: null,
      bannerURL: null,
      newComment: '',
      editing: false,
      editingComment: false,
      editForm: {
        title: '', description: '', headline: '', bannerURL: null, imageURL: null,
      },
      editCommentForm: { description: '' },
      commentKey: '',
      itemImage: null,
      editedItem: {},
      lastLoadedPage: 1,
      isUnshareModalVisible: false,
      isUnshareCommentModalVisible: false,
      isShareModalVisible: false,
      date: null,
      item: {},
      editSlug: '',
    };
  },
  computed: {
    blogTitle() {
      const app = Object.values((this.$store.getters.apps?.apps) || {}).find((x) => x.key === 'blogs');
      if (app?.customizationName) {
        return translateTranslationTable(this.currentLocale, app.customizationName);
      }
      if (app?.name) {
        return translateTranslationTable(this.currentLocale, app.name);
      }
      return this.$t('blogs.title');
    },
    id() {
      return this.$route.params.id;
    },
    isMobile() {
      return window.innerWidth < 576;
    },
    blogPlaceholder() {
      return BlogPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'update',
          'blog',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    canDelete() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'delete',
          'blog',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    canShare() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'share',
          'blog',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    comments() {
      const comments = this.$store.getters.comments.unpaginated;
      this.editCommentForm.description = comments.description;
      return comments;
    },
    recentPosts() {
      const posts = this.$store.getters.blogs?.recent?.unpaginated || [];
      const selectedPosts = posts?.filter((post) => post.key !== this.item.key);
      this.updateBreadcrumbs();
      return selectedPosts?.slice(0, 6);
    },
    placeholder() {
      return Placeholder;
    },

    classifiersData() {
      if (this.$store.getters.classifiers.blog) {
        return this.$store.getters.classifiers.blog.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    currentUrl() {
      return `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.collective.subdomain || 'app',
      ).replace('{slug}', this.collective.slug)}/blogs`;
    },
  },
  watch: {
    item(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchComments();
      }
    },
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    editSlug(value) {
      return value;
    },
  },
  async created() {
    await this.fetchItem(this.id);
    await this.fetchRecentBlogs();
    await this.fetchComments();
    this.updateBreadcrumbs();
    this.date = new Date(this.item?.date);
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    canDeleteComment(comment) {
      return (
        this.loggedUser.key === comment?.createdBy?.userKey
        || this.isStaff
        || checkPermissions(
          'delete',
          'blog',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    canEditComment(comment) {
      return (
        this.loggedUser.key === comment?.createdBy?.userKey
        || this.isStaff
        || checkPermissions(
          'update',
          'blog',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.itemImage = files[0];
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchComments() {
      await this.$store.dispatch('getItems', {
        itemType: 'comments',
        morphType: 'blogs',
        page: this.lastLoadedPage,
        perPage: 16,
        forceAPICall: true,
        requestConfig: {
          key: this.item?.key,
        },
      });
      this.isLoading = false;
    },
    async fetchItem(id) {
      const url = Vue.$cookies.get('type');
      const res = await this.$store.dispatch('getItems', {
        itemType: 'blogs',
        storedKey: 'item',
        forceAPICall: true,
        requestConfig: {
          ...(url !== 'slug' && id.replace(/[^0-9]/g, '').length > 10 ? { blogKey: id } : { slug: id }),
        },
      });
      this.item = res?.data[0];
      this.editForm = { ...this.item };
      this.editForm.title = this.translate(this.item?.title);
      this.editSlug = this.translate(this.item?.slug || this.item?.key);
      this.editForm.description = this.translate(this.item?.content);
      this.editForm.headline = this.translate(this.item?.headline);
      Vue.$cookies.remove('type');
    },
    async fetchRecentBlogs() {
      await this.$store.dispatch('getItems', {
        itemType: 'blogs',
        storedKey: 'recent',
        perPage: 250,
        page: this.lastLoadedPage,
        requestConfig: {
          orderByDate: -1,
        },
      });
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'blog',
        itemType: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          blogKey: this.item?.key,
        },
      });

      this.isLoading = false;
    },
    async toggleLike() {
      await this.$store.dispatch('toggleLike', {
        itemType: 'blogs',
        morphType: 'blogs',
        key: this.item?.key,
      });
    },
    async scrollBottom() {
      const element = document.getElementById('commentForm');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    async postComment() {
      await this.$store.dispatch('postComment', {
        morphType: 'blogs',
        key: this.item?.key,
        comment: this.newComment,
      });
      this.newComment = '';
      this.comments = [];

      this.fetchComments();
      this.item.commentCount++;
    },
    // TODO: Remove this workaround. The BE should always return objects
    getText(value) {
      if (!value) return;
      if (typeof value === 'object') { return value[this.currentLocale] || Object.values(value)[0]; }
      if (value[0] === '<') return value;
      const object = JSON.parse(value);
      return object[this.currentLocale] || Object.values(object)[0];
    },
    selectComment(comment) {
      this.editCommentForm.description = comment.content;
      this.commentKey = comment.key;
      this.editingComment = !this.editingComment;
    },
    // REMOVE COMMENT
    async deleteComment(commentKey) {
      try {
        await this.$store.dispatch('deleteComment', {
          item: {
            itemType: 'comments',
            morphType: 'blogs',
            requestConfig: {
              key: commentKey,
              blogKey: this.id,
            },
          },
        });
        this.isUnshareCommentModalVisible = false;
        this.commentKey = '';
        this.item.commentCount--;
        this.fetchComments();
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    // EDIT COMMENT
    async handleEditComment() {
      try {
        let editedComment = {};
        editedComment = this.editCommentForm.description;
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'comments',
            morphType: 'blogs',

            requestConfig: {
              key: this.commentKey,
              comment: editedComment,
            },
          },
        });
        this.commentKey = '';
        this.editingComment = !this.editingComment;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async deleteItem(item) {
      try {
        await this.$store.dispatch('deleteComment', {
          item: {
            itemType: 'blogs',
            requestConfig: {
              blogKey: item.key,
            },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('unshare.toast-messages.success', { item: 'Blog' }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.isUnshareModalVisible = false;
        this.$router.push(`/${item.poweredBy.slug}/blogs`);
      } catch {}
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    async handleEditItem() {
      try {
        const editedTitle = {};
        const editedContent = {};
        const editedHeadline = {};
        const file = {};
        const itemDelete = {};
        editedTitle[this.currentLocale] = this.editForm.title;
        editedContent[this.currentLocale] = this.editForm.description;
        editedHeadline[this.currentLocale] = this.editForm.headline;
        if (this.imageURL) {
          file.imageURL = this.imageURL;
        } else if (this.editForm.imageURL !== null) {
          file.imageURL = this.editForm.imageURL;
        } else {
          file.imageURL = null;
          itemDelete.deleteImageURL = true;
        }
        if (this.bannerURL) {
          file.bannerURL = this.bannerURL;
        } else if (this.editForm.bannerURL !== null) {
          file.bannerURL = this.editForm.bannerURL;
        } else {
          file.bannerURL = null;
          itemDelete.deleteBannerURL = true;
        }
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'blogs',
            requestConfig: {
              blogKey: this.item.key,
              title: editedTitle,
              headline: editedHeadline,
              content: editedContent,
              date: new Date(this.date),
              ...itemDelete,
              ...((this.editSlug != this.item.key && this.editSlug != this.item.slug) ? { slug: this.validSlug(this.editSlug) } : ''),
            },
          },
          file,
        });
        this.item = result;
        this.editedItem = result;
        this.updateBreadcrumbs();
        this.editing = false;
        this.imageSrc = null;
        this.itemImage = null;
        this.bannerImage = null;
        if (this.editSlug != this.item.key && this.validSlug(this.editSlug) != this.item.slug) {
          setTimeout(() => {
            Vue.$cookies.set('type', 'slug');
            window.location = `${this.currentUrl}/${this.validSlug(this.editSlug)}`;
          }, 800);
        }
      } catch (e) {
        console.log(e.message)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('blogs.slug-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    validSlug(slug) {
      return slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '-');
    },
    // TODO: move to utils
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.blogTitle || 'blogs.breadcrumb-text.blogs-list',
          to: { name: 'blogs' },
        },
        {
          text:
          translateTranslationTable(this.currentLocale, this.item.title)
            || 'blogs.breadcrumb-text.blog-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    translateTranslationTable,
  },
};
</script>
<style>
.blog-container {
  max-width: 1600px;
  margin: 0 auto;
}
.cws-container .cws-content {
  width: calc(100% - 460px - 2rem);
}
.cws-container .cws-sidebar {
  width: 460px;
}

.imagen {
  max-height: 500px !important;
  object-fit: cover !important;
  overflow: hidden;
}

.edit-title {
  font-size: 1.5rem;
  color: #5e5873;
  font-weight: 500;
}
.ql-container .ql-editor span {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.ql-container .ql-editor p {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: black;
}
.form-file-custom {
  position: absolute;
  top: 8px;
  right: 2%;
}
.card-imgage-edit {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0.75rem;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  height: 40px;
}
.card-imgage-edit svg {
  color: #f0f0f0 !important;
}
.content-container img {
  max-width: 100%;
}
.blog-content::v-deep img {
  max-width: 100% !important;
  height: auto !important;
}
@media (max-width: 992px) {
  .cws-container .cws-content {
    width: 100%;
  }
}
</style>
